import "./App.css";
import SideBar from "./component/sidebar/SideBar";
import MainDash from "./component/MainDash/MainDash";
import RightSide from "./component/RightSide/RightSide";

function App() {
  return (
    <div className="App">
      <div className="appGlass">
        <SideBar />
        <MainDash />
        <RightSide/>
      </div>
    </div>
  );
}

export default App;
