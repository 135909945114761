import React from "react";
import "./updates.css";
import { UpdatesData } from "../../Data/Data";

const Updates = () => {
  return <div className="Updates">
    {UpdatesData.map((update,i)=>{
        return(
          <div className="update">
            <img src={update.img} />
            <div className="noti">
                <div className="updateNameNoti">
                    <span className="updateNotiName">{update.name}</span>
                    <span>{update.noti}</span>
                </div>
                <span>{update.time}</span>
            </div>
          
              
            
          </div>
        )
    })}
  </div>;
};

export default Updates;
